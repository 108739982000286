import { RuleFilter } from '../../types/filter';

export interface IClient {
  _id?: string;
  id?: string;
  extID?: string;
  name?: string;
  iconUrl?: string;
  defaultDash?: string;
  dataset_rights?: string[];
  uploadPath?: string;
  audienceUploadPath?: string;
  defaultCrosswalkCombination?: string;
  attributionReportRuleValueRestrictions?: RuleFilter[];
}

export default IClient;

export class Client implements IClient {
  _id: string = '';
  id: string = '';
  extID: string = '';
  name: string = '';
  iconUrl: string = '';
  defaultDash: string = '';
  defaultCrosswalkCombination: string = '';
  dataset_rights: string[] = [];
  uploadPath: string = '';
  attributionReportRuleValueRestrictions: RuleFilter[] = [
    {
      filterId: 'BRAND_LABEL',
      children: [],
    },
    {
      filterId: 'NETWORK',
      children: [],
    },
    {
      filterId: 'PIQ_CHAIN',
      children: [],
    },
    {
      filterId: 'PIQ_CATEGORY',
      children: [],
    },
  ];
}
