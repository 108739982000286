import ActionsBar from 'components/ActionsBar';
import Box, { Type as boxTypes } from 'components/Box';
import { Size as ButtonSize, Type as ButtonType } from 'components/Button';
import { DropdownSearchQueryProvider } from 'components/DropdownSearch';
import Field from 'components/Field';
import { NewInputValue, Type as InputType } from 'components/Input';
import Label from 'components/Label';
import MultipleDropdownSelect from 'components/MultipleDropdownSelect';
import { Client, IClient } from 'domains/clients/types';
import { getClass, getId, getTestId } from 'helpers/components';
import { handleDropdownSearchSyncFilter } from 'helpers/utils';
import { useForm } from 'hooks/useForm';
import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { clientValidationErrors } from 'types/error';
import { v4 as uuid } from 'uuid';
import useFetch from 'hooks/useFetch';
import FetchMethod from 'types/fetchMethod';
import { connect } from 'react-redux';
import * as userSelector from 'store/selectors/user';
import State from 'types/state';
import { get, isEmpty } from 'lodash';
import Flex, { Horizontal } from 'components/Flex';
import ITextValue from '../../../../types/textValue';
import { validationSchema } from './validations';

const LOCALE_FIELD_NAME_LABEL = 'Name';
const LOCALE_FIELD_EXT_ID_LABEL = 'Ext. ID';
const LOCALE_FIELD_ICON_URL_LABEL = 'Icon URL';
const LOCALE_FIELD_DEFAULT_DASH_LABEL = 'Default Dash';
const LOCALE_FIELD_SEGMENT_LABEL = 'Custom Segments';
const LOCALE_FIELD_SCHEDULE_LABEL = 'Custom Schedules';
const LOCALE_FIELD_GENERIC_EVENTS_LABEL = 'Generic Events';
const LOCALE_FIELD_CAT3_LABEL = 'Category 3 Segment Filters';
const LOCALE_FIELD_VENDOR_LABEL = 'Vendor Sources';
const LOCALE_FIELD_CROSSWALK_LABEL = 'Target Export Crosswalks';
const LOCALE_FIELD_CROSSWALK_COMBINATIONS_LABEL = 'Crosswalk Combinations';
const LOCALE_FIELD_DAYPART_LABEL = 'Custom Daypart';
const LOCALE_FIELD_UPLOAD_PATH_LABEL = 'Upload Path';
const LOCALE_FIELD_AUDIENCE_UPLOAD_PATH_LABEL = 'Audience Upload Path';
const LOCALE_FIELD_DEFAULT_CROSSWALK_COMBINATION_LABEL = 'Default HH Footprint';
const LOCALE_FIELD_UPLOAD_PATH_COPY =
  'Target and Impact reports will automatically be placed in separate sub-directories of this path';

const LOCALE_ACTION_OK = 'Save';
const LOCALE_ACTION_CANCEL = 'Cancel';
const LOCALE_ACTION_OK_LOADING = 'Saving...';

export const formComponentName = 'client-form';

const formId = getId(formComponentName);
const formDefaultClass = getClass(formComponentName);

interface IFormProps {
  testId?: string;
  client?: IClient;
  loading?: boolean;
  onSubmit: (client: IClient) => void;
  onCancel: () => void;
  dashboardOptions?: ITextValue[];
  datasetsOptions?: Array<ITextValue & { type?: string }>;
  extIDError: boolean;
  nameError: boolean;
  resetExtIDError: () => void;
  resetNameError: () => void;
  selectedClient: string;
  userEmail: string;
}

const FormComponent: FunctionComponent<IFormProps> = ({
  loading,
  onSubmit,
  onCancel,
  testId,
  dashboardOptions,
  datasetsOptions,
  client,
  extIDError,
  nameError,
  resetExtIDError,
  resetNameError,
  selectedClient,
  userEmail,
}): ReactElement => {
  const { doFetch } = useFetch<ITextValue>();
  const [brandRestrictionOptions, setBrandRestrictionOptions] = useState<
    ITextValue[]
  >([]);
  const [networkRestrictionOptions, setNetworkRestrictionOptions] = useState<
    ITextValue[]
  >([]);
  const [placeIQChainOptions, setPlaceIQChainOptions] = useState<ITextValue[]>(
    [],
  );
  const [placeIQCategoryOptions, setPlaceIQCategoryOptions] = useState<
    ITextValue[]
  >([]);

  const submitForm = (clientFormValues: IClient): void => {
    const { _id: privateId, id, ...clientWithoutId } = clientFormValues;
    onSubmit(clientWithoutId);
  };

  /**
   * @TODO migrate client to add restrictions and remove me please
   */
  const initialClient: IClient = useMemo(() => {
    const emptyClient = new Client();
    if (isEmpty(client)) {
      return emptyClient;
    }
    const clientRestrictions = get(
      client,
      'attributionReportRuleValueRestrictions',
    );
    if (isEmpty(clientRestrictions)) {
      return {
        ...client,
        attributionReportRuleValueRestrictions:
          emptyClient.attributionReportRuleValueRestrictions,
      };
    }
    const attributionReportRuleValueRestrictions = clientRestrictions?.map(
      (restriction, index) => {
        if (isEmpty(restriction) || isEmpty(get(restriction, 'filterId'))) {
          return emptyClient.attributionReportRuleValueRestrictions[index];
        }
        return restriction;
      },
    );
    return { ...client, attributionReportRuleValueRestrictions };
  }, [client]);

  const { errors, setFieldValue, handleSubmit, values } = useForm(
    initialClient,
    validationSchema,
    submitForm,
  );

  const retrieveBrands = useCallback(
    (valueFilter = '', lineBegin = 0, lineEnd = 0) =>
      new Promise<ITextValue[]>((resolve, reject) => {
        setBrandRestrictionOptions([]);

        doFetch({
          endpoint: '/filterValues',
          payload: {
            userEmail,
            datasetIds: [],
            genericEventDatasets: [],
            field: 'BRAND_LABEL',
            valueFilter,
            filterRules: [],
            selectedClient,
            lineBegin,
            lineEnd,
          },
          method: FetchMethod.POST,
          onError: reject,
          onSuccess: (d) => {
            resolve(d);
            setBrandRestrictionOptions(d);
          },
        });
      }),
    [doFetch, selectedClient, userEmail],
  );

  const retrieveNetworks = useCallback(
    (valueFilter = '', lineBegin = 0, lineEnd = 0) =>
      new Promise<ITextValue[]>((resolve, reject) => {
        setNetworkRestrictionOptions([]);

        doFetch({
          endpoint: '/filterValues',
          payload: {
            userEmail,
            datasetIds: [],
            genericEventDatasets: [],
            field: 'NETWORK',
            valueFilter,
            filterRules: [],
            selectedClient,
            lineBegin,
            lineEnd,
          },
          method: FetchMethod.POST,
          onError: reject,
          onSuccess: (d) => {
            resolve(d);
            setNetworkRestrictionOptions(d);
          },
        });
      }),
    [doFetch, selectedClient, userEmail],
  );

  const retrievePlaceIQChain = useCallback(
    (valueFilter = '', lineBegin = 0, lineEnd = 0) =>
      new Promise<ITextValue[]>((resolve, reject) => {
        setPlaceIQChainOptions([]);

        doFetch({
          endpoint: '/filterValues',
          payload: {
            userEmail,
            datasetIds: [],
            genericEventDatasets: [],
            field: 'PLACEIQ',
            childField: 'PIQ_CHAIN',
            valueFilter,
            filterRules: [],
            selectedClient,
            lineBegin,
            lineEnd,
          },
          method: FetchMethod.POST,
          onError: reject,
          onSuccess: (d) => {
            resolve(d);
            setPlaceIQChainOptions(d);
          },
        });
      }),
    [doFetch, selectedClient, userEmail],
  );

  const retrievePlaceIQCategory = useCallback(
    (valueFilter = '', lineBegin = 0, lineEnd = 0) =>
      new Promise<ITextValue[]>((resolve, reject) => {
        setPlaceIQCategoryOptions([]);

        doFetch({
          endpoint: '/filterValues',
          payload: {
            userEmail,
            datasetIds: [],
            genericEventDatasets: [],
            field: 'PLACEIQ',
            childField: 'PIQ_CATEGORY',
            valueFilter,
            filterRules: [],
            selectedClient,
            lineBegin,
            lineEnd,
          },
          method: FetchMethod.POST,
          onError: reject,
          onSuccess: (d) => {
            resolve(d);
            setPlaceIQCategoryOptions(d);
          },
        });
      }),
    [doFetch, selectedClient, userEmail],
  );

  const handleChange = (field: keyof IClient) => (value: NewInputValue) => {
    setFieldValue(field, value);
    if (field === 'extID' && extIDError) {
      resetExtIDError();
    }
    if (field === 'name' && nameError) {
      resetNameError();
    }
  };

  const handleDashboardDropdownChange = (items: ITextValue[]): void =>
    setFieldValue('defaultDash', items.map((item) => item.value) as string[]);

  const handleDefaultCrosswalkDropdownChange = (items: ITextValue[]): void =>
    setFieldValue(
      'defaultCrosswalkCombination',
      (items[0]?.value as string) ?? '',
    );

  const handleBrandRestrictionChange = (items: ITextValue[]): void => {
    setFieldValue('attributionReportRuleValueRestrictions.0', {
      filterId: 'BRAND_LABEL',
      children: items,
    });
  };

  const handleNetworkRestrictionChange = (items: ITextValue[]): void => {
    setFieldValue('attributionReportRuleValueRestrictions.1', {
      filterId: 'NETWORK',
      children: items,
    });
  };

  const handlePlaceIQChainChange = (items: ITextValue[]): void => {
    setFieldValue('attributionReportRuleValueRestrictions.2', {
      filterId: 'PIQ_CHAIN',
      children: items,
    });
  };

  const handlePlaceIQCategoryChange = (items: ITextValue[]): void => {
    setFieldValue('attributionReportRuleValueRestrictions.3', {
      filterId: 'PIQ_CATEGORY',
      children: items,
    });
  };

  const formTestId = useMemo(
    () => getTestId(formComponentName, testId),
    [testId],
  );

  const selectedDatasets = useCallback(
    (options: Array<ITextValue & { type?: string }>) =>
      options?.filter((option) =>
        values.dataset_rights?.includes(option.value as string),
      ),
    [values.dataset_rights],
  );

  const handleMultiDropdownChange = (
    items: Array<ITextValue & { type?: string }>,
    type?: string,
  ): void => {
    const allSelected = selectedDatasets(datasetsOptions ?? []);
    const filteredOutDatasetType = allSelected.filter(
      (selected) => selected.type !== type,
    );
    if (type === 'CROSSWALK_COMBINATION') {
      if (
        !items.some((item) => item.value === values.defaultCrosswalkCombination)
      ) {
        setFieldValue('defaultCrosswalkCombination', '');
      }
    }
    const updatedSelected = filteredOutDatasetType.concat(items);
    return setFieldValue(
      'dataset_rights',
      updatedSelected.map((item) => item.value) as string[],
    );
  };

  const dashboards = useMemo(
    () =>
      dashboardOptions?.filter((option) =>
        values.defaultDash?.includes(option.value as string),
      ),
    [dashboardOptions, values.defaultDash],
  );

  const filterDashboardOptions: DropdownSearchQueryProvider = useCallback(
    (valueFilter = '') =>
      handleDropdownSearchSyncFilter(
        valueFilter,
        dashboardOptions as ITextValue[],
      ),
    [dashboardOptions],
  );

  const defaultCrosswalkCombination = useMemo(
    () =>
      datasetsOptions?.filter(
        (option) => option.value === values.defaultCrosswalkCombination,
      ),
    [datasetsOptions, values.defaultCrosswalkCombination],
  );

  const filteredDatasets = datasetsOptions?.filter(
    (option) =>
      option.type === 'CROSSWALK_COMBINATION' &&
      values.dataset_rights.includes(option.value),
  );

  const filterCrosswalkCombinationsOptions: DropdownSearchQueryProvider =
    useCallback(
      (valueFilter = '') =>
        handleDropdownSearchSyncFilter(
          valueFilter,
          filteredDatasets as ITextValue[],
        ),
      [datasetsOptions, values.dataset_rights],
    );

  const supportedDatasetTypes = [
    {
      type: 'SEGMENT',
      label: LOCALE_FIELD_SEGMENT_LABEL,
      placeholder: 'Search Custom Segment Datasets',
    },
    {
      type: 'SCHEDULE',
      label: LOCALE_FIELD_SCHEDULE_LABEL,
      placeholder: 'Search Custom Schedule Datasets',
    },
    {
      type: 'GENERIC_EVENTS',
      label: LOCALE_FIELD_GENERIC_EVENTS_LABEL,
      placeholder: 'Search Generic Events Datasets',
    },
    {
      type: 'CAT3_SEGMENT',
      label: LOCALE_FIELD_CAT3_LABEL,
      placeholder: 'Search Cat 3 Segment Datasets',
    },
    {
      type: 'VENDOR_SOURCE',
      label: LOCALE_FIELD_VENDOR_LABEL,
      placeholder: 'Search Vendor Sources Datasets',
    },
    {
      type: 'CROSSWALK',
      label: LOCALE_FIELD_CROSSWALK_LABEL,
      placeholder: 'Search Crosswalk Datasets',
    },
    {
      type: 'CROSSWALK_COMBINATION',
      label: LOCALE_FIELD_CROSSWALK_COMBINATIONS_LABEL,
      placeholder: 'Search Crosswalk Combination Datasets',
    },
    {
      type: 'DAYPART_TIMETABLE',
      label: LOCALE_FIELD_DAYPART_LABEL,
      placeholder: 'Search Daypart Timetables',
      max: 1,
    },
  ];

  const datasetDropdowns = (): React.ReactElement => {
    const dropdowns = supportedDatasetTypes.map((supportedDataset) => {
      const options =
        datasetsOptions?.filter(
          (dataset) => supportedDataset.type === dataset.type,
        ) ?? [];
      const filterDatasetsOptions: DropdownSearchQueryProvider = (
        valueFilter = '',
      ) => handleDropdownSearchSyncFilter(valueFilter, options as ITextValue[]);

      return (
        <div className="search-dropdown-container" key={uuid()}>
          <div className="label-container">
            <Label text={supportedDataset.label} />
          </div>
          <MultipleDropdownSelect
            ignoreGrouping
            placeholder={supportedDataset.placeholder}
            maximumChipsSelected={supportedDataset.max ?? -1}
            options={options}
            onChange={(e) =>
              handleMultiDropdownChange(e, supportedDataset.type)
            }
            canReorder={false}
            selected={selectedDatasets(options)}
            queryProvider={filterDatasetsOptions}
          />
        </div>
      );
    });

    return <>{dropdowns}</>;
  };

  return (
    <section className={formDefaultClass} data-testid={formTestId}>
      <Box type={boxTypes.primary}>
        <form onSubmit={handleSubmit} data-testid={`${formTestId}-form`}>
          <Field
            label={LOCALE_FIELD_EXT_ID_LABEL}
            type={InputType.text}
            id={`${formId}-extID`}
            name={`${formId}-input`}
            onChange={handleChange('extID')}
            value={values.extID}
            hasError={extIDError}
            errorMessage={clientValidationErrors.duplicatedExtID}
          />
          <Field
            label={LOCALE_FIELD_NAME_LABEL}
            type={InputType.text}
            id={`${formId}-name`}
            name={`${formId}-input`}
            onChange={handleChange('name')}
            value={values.name}
            hasError={nameError}
            errorMessage={clientValidationErrors.duplicatedName}
          />
          {datasetDropdowns()}
          {filteredDatasets?.length && (
            <div className="search-dropdown-container">
              <div className="label-container">
                <Label
                  text={LOCALE_FIELD_DEFAULT_CROSSWALK_COMBINATION_LABEL}
                />
              </div>
              <MultipleDropdownSelect
                ignoreGrouping
                placeholder="Search Crosswalk Combination Datasets"
                options={datasetsOptions}
                onChange={handleDefaultCrosswalkDropdownChange}
                selected={defaultCrosswalkCombination}
                queryProvider={filterCrosswalkCombinationsOptions}
                maximumChipsSelected={1}
              />
            </div>
          )}
          <Field
            label={LOCALE_FIELD_ICON_URL_LABEL}
            type={InputType.text}
            id={`${formId}-iconUrl`}
            name={`${formId}-input`}
            onChange={handleChange('iconUrl')}
            value={values.iconUrl}
          />
          <div className="search-dropdown-container">
            <div className="label-container">
              <Label text={LOCALE_FIELD_DEFAULT_DASH_LABEL} />
            </div>
            <MultipleDropdownSelect
              ignoreGrouping
              placeholder="Search Dash"
              options={dashboardOptions}
              onChange={handleDashboardDropdownChange}
              selected={dashboards}
              queryProvider={filterDashboardOptions}
              maximumChipsSelected={1}
            />
          </div>

          <Field
            label={LOCALE_FIELD_UPLOAD_PATH_LABEL}
            type={InputType.text}
            id={`${formId}-uploadPath`}
            name={`${formId}-input`}
            onChange={handleChange('uploadPath')}
            value={values.uploadPath}
            hasError={!!errors.uploadPath}
            errorMessage={errors.uploadPath?.toString() ?? ''}
          />

          <Field
            label={LOCALE_FIELD_AUDIENCE_UPLOAD_PATH_LABEL}
            type={InputType.text}
            id={`${formId}-audienceUploadPath`}
            name={`${formId}-input`}
            onChange={handleChange('audienceUploadPath')}
            value={values.audienceUploadPath}
            hasError={!!errors.audienceUploadPath}
            errorMessage={errors.audienceUploadPath?.toString() ?? ''}
          />

          <section>
            <Label text="Imp4ct Rule Restrictions" />
            <article className="inline-form-fields">
              <Flex horizontal={Horizontal.between}>
                <div className="inline-form-field-wrapper">
                  <Field
                    type={InputType.select}
                    options={[{ text: 'Brand', value: 'brand' }]}
                    value="brand"
                    disabled
                  />
                </div>
                <div className="inline-form-field-wrapper">
                  <Field
                    type={InputType.select}
                    options={[{ text: 'is one of', value: 'is_one_of' }]}
                    value="is_one_of"
                    disabled
                  />
                </div>
                <div className="inline-form-field-wrapper">
                  <MultipleDropdownSelect
                    queryProvider={retrieveBrands}
                    loading={false}
                    testId="client-brand-restriction-field"
                    selected={get(
                      values,
                      'attributionReportRuleValueRestrictions.0.children',
                      [],
                    )}
                    options={brandRestrictionOptions}
                    onChange={handleBrandRestrictionChange}
                    placeholder="Select Brand Restriction"
                    enableHighlight
                    fetchedOptions
                  />
                </div>
              </Flex>
            </article>

            <article className="inline-form-fields">
              <Flex horizontal={Horizontal.between}>
                <div className="inline-form-field-wrapper">
                  <Field
                    type={InputType.select}
                    options={[{ text: 'Network', value: 'network' }]}
                    value="network"
                    disabled
                  />
                </div>
                <div className="inline-form-field-wrapper">
                  <Field
                    type={InputType.select}
                    options={[{ text: 'is one of', value: 'is_one_of' }]}
                    value="is_one_of"
                    disabled
                  />
                </div>
                <div className="inline-form-field-wrapper">
                  <MultipleDropdownSelect
                    queryProvider={retrieveNetworks}
                    loading={false}
                    testId="client-network-restriction-field"
                    selected={get(
                      values,
                      'attributionReportRuleValueRestrictions.1.children',
                      [],
                    )}
                    options={networkRestrictionOptions}
                    onChange={handleNetworkRestrictionChange}
                    placeholder="Select Network Restriction"
                    enableHighlight
                    fetchedOptions
                  />
                </div>
              </Flex>
            </article>

            <article className="inline-form-fields">
              <Flex horizontal={Horizontal.between}>
                <div className="inline-form-field-wrapper">
                  <Field
                    type={InputType.select}
                    options={[{ text: 'PlaceIQ Chain', value: 'piq_chain' }]}
                    value="piq_chain"
                    disabled
                  />
                </div>
                <div className="inline-form-field-wrapper">
                  <Field
                    type={InputType.select}
                    options={[{ text: 'is one of', value: 'is_one_of' }]}
                    value="is_one_of"
                    disabled
                  />
                </div>
                <div className="inline-form-field-wrapper">
                  <MultipleDropdownSelect
                    queryProvider={retrievePlaceIQChain}
                    loading={false}
                    testId="client-piq-chain-restriction-field"
                    selected={get(
                      values,
                      'attributionReportRuleValueRestrictions.2.children',
                      [],
                    )}
                    options={placeIQChainOptions}
                    onChange={handlePlaceIQChainChange}
                    placeholder="Select PIQ Chain Restriction"
                    enableHighlight
                    fetchedOptions
                  />
                </div>
              </Flex>
            </article>

            <article className="inline-form-fields">
              <Flex horizontal={Horizontal.between}>
                <div className="inline-form-field-wrapper">
                  <Field
                    type={InputType.select}
                    options={[
                      { text: 'PlaceIQ Category', value: 'piq_category' },
                    ]}
                    value="piq_category"
                    disabled
                  />
                </div>
                <div className="inline-form-field-wrapper">
                  <Field
                    type={InputType.select}
                    options={[{ text: 'is one of', value: 'is_one_of' }]}
                    value="is_one_of"
                    disabled
                  />
                </div>
                <div className="inline-form-field-wrapper">
                  <MultipleDropdownSelect
                    queryProvider={retrievePlaceIQCategory}
                    loading={false}
                    testId="client-piq-category-restriction-field"
                    selected={get(
                      values,
                      'attributionReportRuleValueRestrictions.3.children',
                      [],
                    )}
                    options={placeIQCategoryOptions}
                    onChange={handlePlaceIQCategoryChange}
                    placeholder="Select PIQ Category Restriction"
                    enableHighlight
                    fetchedOptions
                  />
                </div>
              </Flex>
            </article>
          </section>

          <span className={`${formDefaultClass}-uploadPath_copy`}>
            {LOCALE_FIELD_UPLOAD_PATH_COPY}
          </span>
          <ActionsBar
            cancel
            cancelLabel={LOCALE_ACTION_CANCEL}
            disabledOK={loading}
            margin
            ok
            okLabel={loading ? LOCALE_ACTION_OK_LOADING : LOCALE_ACTION_OK}
            okSize={ButtonSize.medium}
            okType={ButtonType.submit}
            onCancel={onCancel}
          />
        </form>
      </Box>
    </section>
  );
};

const mapStateToProps = (
  state: State,
): { selectedClient: string; userEmail: string } => ({
  selectedClient: userSelector.getSelectedClient(state),
  userEmail: userSelector.getEmail(state),
});

export const ClientForm = connect(mapStateToProps)(FormComponent);
