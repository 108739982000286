import FieldWrapper from 'components/Field/Wrapper';
import { Type as InputType, NewInputValue } from 'components/Input';
import Label from 'components/Label';
import get from 'lodash/fp/get';
import React, { FunctionComponent, ReactElement } from 'react';
import {
  LOCALE_HH_FOOTPRINT,
  LOCALE_HH_FOOTPRINT_PLACEHOLDER,
  LOCALE_HH_TOOLTIP,
} from 'components/TargetBuilder/locale';
import { useDatasets } from 'domains/datasets/hooks';
import { getTextValueDatasets } from 'components/QueryBuilder/adapters';
import QueryBuilderItem from 'components/QueryBuilder/components/QueryBuilderItem';
import MultipleDropdownSelect from 'components/MultipleDropdownSelect';
import { compact } from 'lodash';
import { reportTypeEquivalences } from '../../domains/reports/types';
import { getClass, getId } from '../../helpers/components';
import useUserPermissions from '../../hooks/useUserPermissions';
import Field from '../Field';
import SegmentedControl from '../SegmentedControl';
import {
  DOM_KEY_REPORT_SETTING_GRANULARITY,
  DOM_KEY_REPORT_SETTING_SAMPLE_FACTOR,
  DOM_KEY_REPORT_SETTING_TIME_FRAME,
  DOM_KEY_REPORT_SETTING_TIME_ZONE,
  DOM_KEY_REPORT_SETTING_WEIGHTING_MODE,
} from './constants';
import {
  LOCALE_SETTING_GRANULARITY_LABEL,
  LOCALE_SETTING_SAMPLE_FACTOR_LABEL,
  LOCALE_SETTING_TIME_FRAME_LABEL,
  LOCALE_SETTING_TIME_ZONE_LABEL,
  LOCALE_SETTING_WEIGHTING_MODE_LABEL,
} from './locale';
import { IOwnProps } from './types';

export const reportSettingsFormComponentName = 'report-settings';

const reportSettingsClass = getClass(reportSettingsFormComponentName);
const reportFormId = getId(reportSettingsFormComponentName);

const reportFormReportSettingsTimeFrameClass = getClass(
  reportSettingsFormComponentName,
  { concat: [DOM_KEY_REPORT_SETTING_TIME_FRAME] },
);

const reportFormReportSettingsWeightingModeClass = getClass(
  reportSettingsFormComponentName,
  { concat: [DOM_KEY_REPORT_SETTING_WEIGHTING_MODE] },
);
const reportFormReportSettingsGranularityClass = getClass(
  reportSettingsFormComponentName,
  { concat: [DOM_KEY_REPORT_SETTING_GRANULARITY] },
);
const reportFormReportSettingsTimeZoneClass = getClass(
  reportSettingsFormComponentName,
  { concat: [DOM_KEY_REPORT_SETTING_TIME_ZONE] },
);
const reportFormReportSettingsSampleFactorClass = getClass(
  reportSettingsFormComponentName,
  { concat: [DOM_KEY_REPORT_SETTING_SAMPLE_FACTOR] },
);

const getOptionsTimeFrame = get('report.query.time_frame');
const getOptionsWeight = get('report.query.weight');
const getOptionsLevel = get('report.query.level');
const getOptionsTimeZone = get('report.query.time_zone');

export const ReportSettingsForm: FunctionComponent<IOwnProps> = (
  props: IOwnProps,
): ReactElement => {
  const {
    report,
    settings,
    businessData,
    setQueryTimeFrame,
    setBaseReportNotSaved,
    setQueryWeight,
    setQueryLevel,
    setQueryTimeZone,
    setQuerySampleFactor,
    setReportQueryCrosswalkCombination,
  } = props;

  const {
    time_frame: timeFrame,
    weight,
    level,
    time_zone: timeZone,
    sample_factor: sampleFactor,
  } = settings;

  const { checkPermissions } = useUserPermissions();
  const { getCrosswalkCombinationDatasetsForSelectedClient } = useDatasets();
  const crosswalkCombinationDatasets =
    getCrosswalkCombinationDatasetsForSelectedClient();
  const crosswalkCombinationOptions = getTextValueDatasets(
    crosswalkCombinationDatasets,
  );

  const selectedCrosswalkCombination = compact([
    crosswalkCombinationOptions?.find(
      (item) => item.value === report?.query?.genpop_crosswalk_combination,
    ),
  ]);

  return (
    <div className={reportSettingsClass}>
      {checkPermissions(
        `${reportTypeEquivalences(report.type)}.settings.time_frame::view`,
      ) && (
        <FieldWrapper>
          <Label text={LOCALE_SETTING_TIME_FRAME_LABEL} />
          <div className={reportFormReportSettingsTimeFrameClass}>
            <SegmentedControl
              options={getOptionsTimeFrame(businessData)}
              onSelect={(newTimeFrame: string): void => {
                setQueryTimeFrame(newTimeFrame);
                setBaseReportNotSaved();
              }}
              selected={timeFrame}
            />
          </div>
        </FieldWrapper>
      )}

      {checkPermissions(
        `${reportTypeEquivalences(report.type)}.settings.weight_mode::view`,
      ) && (
        <FieldWrapper>
          <Label text={LOCALE_SETTING_WEIGHTING_MODE_LABEL} />
          <div className={reportFormReportSettingsWeightingModeClass}>
            <SegmentedControl
              options={getOptionsWeight(businessData)}
              onSelect={(newWeight: string): void => {
                setQueryWeight(newWeight);
                setBaseReportNotSaved();
              }}
              selected={weight}
            />
          </div>
        </FieldWrapper>
      )}
      {checkPermissions(
        `${reportTypeEquivalences(report.type)}.settings.granularity::view`,
      ) && (
        <FieldWrapper>
          <Label text={LOCALE_SETTING_GRANULARITY_LABEL} />
          <div className={reportFormReportSettingsGranularityClass}>
            <SegmentedControl
              options={getOptionsLevel(businessData)}
              onSelect={(newLevel: string): void => {
                setQueryLevel(newLevel);
                setBaseReportNotSaved();
              }}
              selected={level}
            />
          </div>
        </FieldWrapper>
      )}
      {checkPermissions(
        `${reportTypeEquivalences(report.type)}.settings.time_zone::view`,
      ) && (
        <FieldWrapper>
          <Label text={LOCALE_SETTING_TIME_ZONE_LABEL} />
          <div className={reportFormReportSettingsTimeZoneClass}>
            <SegmentedControl
              options={getOptionsTimeZone(businessData)}
              onSelect={(newTimeZone: string): void => {
                setQueryTimeZone(newTimeZone);
                setBaseReportNotSaved();
              }}
              selected={timeZone}
            />
          </div>
        </FieldWrapper>
      )}
      {checkPermissions(
        `${reportTypeEquivalences(report.type)}.settings.sample_factor::view`,
      ) && (
        <div className={reportFormReportSettingsSampleFactorClass}>
          <Field
            label={LOCALE_SETTING_SAMPLE_FACTOR_LABEL}
            type={InputType.number}
            id={`${reportFormId}-setting-input`}
            testId={`${reportFormId}-setting-input`}
            name={`${reportFormId}-input`}
            onChange={(newSampleFactor: NewInputValue): void => {
              setQuerySampleFactor(+newSampleFactor as number);
              setBaseReportNotSaved();
            }}
            value={sampleFactor ?? 1}
          />
        </div>
      )}
      {/* checkPermissions('targets.settings.crosswalk_combinations::view') && */}
      {report.type === 'target' && (
        <QueryBuilderItem
          label={LOCALE_HH_FOOTPRINT}
          labelMinWidth={120}
          tooltip={LOCALE_HH_TOOLTIP}
        >
          <MultipleDropdownSelect
            ignoreGrouping
            options={crosswalkCombinationOptions}
            onChange={(option) =>
              setReportQueryCrosswalkCombination(option[0]?.value as string)
            }
            disabled={
              !checkPermissions('targets.settings.crosswalk_combinations::view')
            }
            trackingId="crosswalk_combination_dropdown"
            testId="crosswalk_combination_dropdown"
            selected={selectedCrosswalkCombination}
            placeholder={LOCALE_HH_FOOTPRINT_PLACEHOLDER}
            maximumChipsSelected={1}
          />
        </QueryBuilderItem>
      )}
    </div>
  );
};
