export enum Actions {
  SEGMENT_CLONE = 'clone',
  SEGMENT_COPY = 'copy',
  SEGMENT_CREATE = 'create',
  SEGMENT_CROSSWALK_SOURCE = 'crosswalk_sources',
  SEGMENT_DATASET_DATERANGE = 'customScheduleDateRange',
  SEGMENT_CUSTOM_DATASET_DATA = 'customDatasetData',
  SEGMENT_DOWNLOAD = 'download',
  SEGMENT_EDIT = 'edit',
  SEGMENT_EDIT_MANY = 'edit-many',
  SEGMENT_EDITFIELDS = 'editFields',
  SEGMENT_EXECUTE = 'execute',
  SEGMENT_LOGIN = 'login',
  SEGMENT_TOGGLE_IS_PUBLIC = 'public',
  SEGMENT_TOGGLE_IS_READ_ONLY = 'read-only',
  SEGMENT_REPORT_JOB = 'job',
  SEGMENT_STATUS = 'status',
  SEGMENT_IMPORT = 'import',
  SEGMENT_BUSINESS_DATA = 'businessData',
  SEGMENT_DOWNLOAD_USERS = 'download-users',
  SEGMENT_REPORT_PAYLOAD = 'payload',
  SEGMENT_ATTR_REPORT_PAYLOAD = 'attribution-payload',
}

export enum Index {
  SEGMENT_ADMIN = 'admin',
  SEGMENT_ATTRIBUTION_REPORTS_V1 = 'attribution-report',
  SEGMENT_ATTRIBUTION_REPORTS = 'attribution-reports',
  SEGMENT_CLIENTS = 'clients',
  SEGMENT_DASHBOARDS = 'dashboards',
  SEGMENT_PERMISSIONS = 'permissions',
  SEGMENT_DATASETS = 'datasets',
  SEGMENT_UPLOADS = 'uploads',
  SEGMENT_EVENTS = 'events',
  SEGMENT_FILTER_VALUES = 'filterValues',
  SEGMENT_FILES = 'files',
  SEGMENT_HOME = '/',
  SEGMENT_METRIC = 'metric',
  SEGMENT_LOGOUT = 'logout',
  SEGMENT_LOGIN = 'login',
  SEGMENT_LOGS = 'logs',
  SEGMENT_REPORTS = 'reports',
  SEGMENT_PLATFORM_REPORT = 'standard',
  SEGMENT_AUDIENCE_INSIGHTS_REPORT = 'audience-insights',
  SEGMENT_REPORTS_V1 = 'report',
  SEGMENT_ROLES = 'roles',
  SEGMENT_RUNNING_QUERIES = 'queries',
  SEGMENT_TARGETS = 'targets',
  SEGMENT_TARGETS_V1 = 'target',
  SEGMENT_USERS = 'users',
  SEGMENT_UI_CONFIG = 'ui-config',
  SEGMENT_HEALTH_CHECK = 'health-check',
  SEGMENT_LIVENESS = 'liveness',
  SEGMENT_BASE_API = 'api',
  SEGMENT_QUERIES = 'queries',
  SEGMENT_TOOLS = 'tools',
  SEGMENT_BUSINESS_DATA = 'businessData',
  SEGMENT_GENERATE_EXPOSURE_SEGMENTS = 'generate-exposure-segments',
  SEGMENT_RESULT_SELECTIONS = 'result-selections',
  SEGMENT_INITIALIZE_MULTIPART_UPLOAD = 'initializeMultipartUpload',
  SEGMENT_GET_MULTIPART_PRE_SIGNED_URLS = 'getMultipartPreSignedUrls',
  SEGMENT_FINALIZE_MULTIPART_UPLOAD = 'finalizeMultipartUpload',
  SEGMENT_UPLOADS_CONFIG = 'config',
  SEGMENT_PRESENTATION = 'presentation',
  SEGMENT_SEGMENT = 'segment',
  SEGMENT_USER_DIMENSION = 'user-dimension',
  SEGMENT_CROSSWALK_COMBINATION = 'crosswalk-combination',
  SEGMENT_CONFIG_OPTIONS = 'config-options',
}

export const getUrl = (routes: string[]): string =>
  routes.join('/').replace('//', '/');
