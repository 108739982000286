import IClient from 'domains/clients/types';
import { flow, get, find, map } from 'lodash/fp';
import State from 'types/state';

export const getDomains = get('domains');
export const getMetrics = flow(getDomains, get('metrics'));
export const getDimensions = flow(getDomains, get('dimensions'));
export const getDatasets = flow(getDomains, get('datasets'));
export const getClients = flow(getDomains, get('clients'));
export const getDomainsDateRanges = flow(getDomains, get('dateRanges'));

export const getClientName = (id: string | undefined, state: State): string => {
  const clientList = get('domains.clients', state);
  const client = find({ id }, clientList);
  return get('name', client);
};

export const getClientDetail = (
  id: string | undefined,
  state: State,
): IClient => {
  const clientList = get('domains.clients', state);
  const client = find({ id }, clientList);
  return client;
};

export const getDatasetNames = (
  idList: string[] | undefined,
  state: State,
): string[] => {
  const datasetList = get('domains.datasets', state);
  return map((id: string) => {
    const dataset = find({ id }, datasetList);
    return get('name', dataset);
  }, idList);
};
